import React from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { Link } from 'gatsby'
import { EllicotLayout } from 'components/EllicotLayout'
import { SearchBar } from '../components/SearchBar'
import { NewListing } from '../components/NewListing'
import { services } from '@services'
import { GetListingsType } from '@services/repliers/repliers-services.type'
import { GetListingsOptionsType } from '@services/repliers/get-listings.service'
import { GoogleMap } from '../components/GoogleMap'
import { Pagination } from '../components/Pagination'
import styled from 'styled-components'
import { FlexDiv } from 'design-system/FlexDiv'
import bathIcon from '../assets/icons/bath.svg'
import bedIcon from '../assets/icons/bed.svg'
import heartIcon from '../assets/icons/heart.svg'
import areaIcon from '../assets/icons/area.svg'
import userIcon from '../assets/icons/person.svg'
import calendarIcon from '../assets/icons/calendar.svg'
import { appConfig } from 'config'
import { GeocoderLocation } from 'components/GeocodeInput'
import Cookies from 'js-cookie'
import { tryParseJSON } from 'utils/lifted/tryParseJson'
const CurrencyFormat = require('react-currency-format')
import { Contact } from '../components/Contact'

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 94%;
  margin: 1em 0 0;
`

const ListingCard = styled.div`
  height: 400px;
  width: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`
const MyDiv = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  z-index: 100;
  width: 100%;
  color: white;
`
const MyDiv2 = styled.div`
  display: flex;
  width: 40%;
  padding: 5px;
  padding-top: 10px;
  align-items: center;
  justify-content: center;
  background: black;
  font-size: 14px;
`
const MyDiv3 = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  width: 32%;
  padding: 5px;
  padding-top: 8px;
  align-items: center;
  background: gray;
  opacity: 0.8;
  border-radius: 5px;
  justify-content: center;
`

const ContentDiv = styled.div`
  width: 300px;
  height: 50%;
  word-break: break-all;
  word-wrap: break-word;
`
const ContentDiv2 = styled.div`
  display: flex;
  width: 300px;
  height: 15%;
  word-break: break-all;
  word-wrap: break-word;
`
const NoStyleLink = styled(Link)`
  :link {
    text-decoration: inherit;
    color: inherit;
  }
  :visited {
    text-decoration: inherit;
    color: inherit;
  }
`

const PropertiesPage: React.FunctionComponent = () => {
  const [searchOptions, setSearchOptions] = React.useState<
    GetListingsOptionsType
  >()
  const [listings, setListings] = React.useState<GetListingsType>()
  const [page, setPage] = React.useState<number>(1)
  const [mapSelectedProperty, setMapSelectedProperty] = React.useState<string>()

  React.useEffect(() => {
    console.log('ran')
    if (searchOptions) {
      console.log(searchOptions)
      if (searchOptions.label) {
        console.log(searchOptions.label)
        Cookies.set('locationLabel', JSON.stringify(searchOptions.label))
      }

      console.log('ran')
      services.repliers
        .getListings({ ...searchOptions, page })
        .then(setListings)
    }
  }, [searchOptions, page])

  const handleSearch = (data: GetListingsOptionsType) => {
    setPage(1)

    console.log(data)
    setSearchOptions(data)
  }

  const parseQuerySearchLocation = () => {
    const [search] = useQueryParam<string>('search')
    const currentSelection = Cookies.get('currentSelection')
    const parsed = tryParseJSON(currentSelection)

    if (parsed && parsed.bounds && typeof google !== "undefined") {
      console.log(parsed)
      const GeocodedLocation = {
        label: parsed.label,
        bounds: new google.maps.LatLngBounds(
          new google.maps.LatLng(parsed.bounds.south, parsed.bounds.west),
          new google.maps.LatLng(parsed.bounds.north, parsed.bounds.east),
        ),
        position: parsed.position,
      }

      return GeocodedLocation as GeocoderLocation
    }
  }

  return (
    <EllicotLayout className="propPage2">
      <div style={{ background: '#f5f5f5' }} className="propPage">
        <SearchBar
          initialLocation={parseQuerySearchLocation()}
          handleSearch={handleSearch}
        />
        {listings && listings.count > 0 && (
          <PaginationWrapper>
            <Pagination
              setPage={setPage}
              numPages={listings.numPages}
              currentPage={listings.page}
            />
          </PaginationWrapper>
        )}
        <NewListing listings={listings?.data || []} />

      </div>
      <Contact />
    </EllicotLayout>
  )
}

export default PropertiesPage